<template>
  <WithOldVetBar>
    <div class="BrandSection mt-5">
      <h2 class="brand-h2">
        <span data-unit="success-title" v-if="showPasswordResetSuccessSection"
          >Check your email</span
        >
        <span data-unit="title" v-else>Request a new password</span>
      </h2>
      <form
        @submit="onChangePasswordButtonClicked"
        class="mt-2"
        data-unit="request-password-reset-form"
        v-if="!showPasswordResetSuccessSection"
        ref="form"
      >
        <div class="my-3 w-50">
          <h3 class="brand-h3">Your email</h3>
          <div class="form-group">
            <label class="d-none" data-unit="email-label" for="new">
              Your email
            </label>
            <input
              class="form-control brand-input"
              data-hj-suppress
              data-unit="email-input"
              id="new"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              placeholder="Enter the email address you use to log in"
              required=""
              type="text"
              v-model="passwordResetForm.email"
            />
          </div>
        </div>
        <div
          class="alert alert-danger w-75"
          v-if="showPasswordResetErrorSection"
          data-unit="error-section"
        >
          Sorry, there was an error processing your request. <br />
          Please try again or contact customer support.
        </div>
        <button
          class="btn brand-button my-2 w-50"
          data-unit="request-password-reset-button"
          type="submit"
        >
          Email me a password reset link
        </button>
        <router-link class="w-50 d-block" to="/login">I remembered my password</router-link>
      </form>
      <div v-if="showPasswordResetSuccessSection">
        <div data-unit="success-message">
          An email has been sent to <b>{{ passwordResetForm.email }} </b>. Please click the link in
          the email when you get it.
        </div>
        <router-link class="btn brand-button mt-2 w-50" to="/login" data-unit="return-button">
          Return to login page
        </router-link>
      </div>
    </div>
  </WithOldVetBar>
</template>

<script lang="js">
import Vue from 'vue';
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";


export default Vue.extend({
  name: 'SetNewPassword',
  components: {WithOldVetBar},
  props: [],
  inject: ['Api'],
  data() {
    return {
      passwordResetForm: {
        email: '',
      },
      showPasswordResetSuccessSection: false,
      showPasswordResetErrorSection: false,
    };
  },
  methods: {
    async requestPasswordReset(email) {
      this.showPasswordResetErrorSection = false;
      this.showPasswordResetSuccessSection = false;
      const result = await this.Api.requestPasswordReset(email);
      if (result.success) {
        this.showPasswordResetSuccessSection = true;
      } else {
        this.showPasswordResetErrorSection = true;
      }
    },
    async onChangePasswordButtonClicked(event) {
      event.preventDefault();
      const isValidForm = this.$refs.form.checkValidity();
      if (isValidForm) {
        await this.requestPasswordReset(this.passwordResetForm.email);
      }
    },
  },
});
</script>
